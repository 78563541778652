import authSlice from "app/redux/auth/authSlice";
import balancesSlice from "app/redux/balances/balancesSlice";
import dashboardSlice from "app/redux/dashboard/dashboardSlice";
import referralsSlice from "app/redux/referrals/referralsSlice";
import settingsSlice from "app/redux/settings/settingsSlice";
import transactionsSlice from "app/redux/transactions/transactionsSlice";
import {combineReducers} from "redux";


const appReducer = combineReducers({
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  transactions: transactionsSlice.reducer,
  balances: balancesSlice.reducer,
  referrals: referralsSlice.reducer,
  settings: settingsSlice.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/loggedOut') {
    /* Clear All Data on logout */
    state = undefined;
  }
  if (action.type === 'auth/setMode') {
    /* Reset All Data except auth on mode change */
    state = {auth: state.auth};
  }
  
  return appReducer(state, action)
}


